.loader {
	width: 50px;
	aspect-ratio: 1;
	border-radius: 50%;
	border: 8px solid #0000;
	border-right-color: #ffa50097;
	position: relative;
	animation: loaderAnim 1s infinite linear;
}
.loader:before,
.loader:after {
	content: "";
	position: absolute;
	inset: -8px;
	border-radius: 50%;
	border: inherit;
	animation: inherit;
	animation-duration: 2s;
}
.loader:after {
	animation-duration: 4s;
}
@keyframes loaderAnim {
	100% {
		transform: rotate(1turn);
	}
}

@keyframes expand {
	from {
		max-height: 0;
		color: transparent;
	}
	to {
		max-height: 500px;
		color: #4b5563;
	}
}

.expand {
	animation-name: expand;
	animation-duration: 0.5s;
	max-height: 500px;
	padding-bottom: 2rem;
}

@keyframes collapse {
	from {
		max-height: 500px;
		color: #4b5563;
	}
	to {
		max-height: 0;
		color: transparent;
	}
}

.collapse {
	animation-name: collapse;
	animation-duration: 0.3s;
	max-height: 0;
}

@media (min-width: 1024px) {
	@keyframes navAnim {
		from {
			color: black !important;
		}
		to {
			color: white !important;
		}
	}

	.navAnim {
		animation-name: navAnim;
		animation-duration: 3s;
		color: white !important;
	}

	@keyframes navOrigin {
		from {
			color: white !important;
		}
		to {
			color: black !important;
		}
	}

	.navOrigin {
		animation-name: navOrigin;
		animation-duration: 1;
		background-color: white;
		color: black !important;
	}

	@keyframes headerBgAnim {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.headerBgAnim::before {
		animation-name: headerBgAnim;
		animation-duration: 1s;
	}

	@keyframes headerBgOrigin {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	.headerBgOrigin::before {
		animation-name: headerBgOrigin;
		animation-duration: 1s;
	}

	.headerBg {
		position: relative;
	}

	.headerBg::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, #e65c00 0%, #f09819 100%);
		opacity: 0;
		animation-fill-mode: forwards;
		z-index: -10;
	}
}
