.loader {
  aspect-ratio: 1;
  border: 8px solid #0000;
  border-right-color: #ffa50097;
  border-radius: 50%;
  width: 50px;
  animation: 1s linear infinite loaderAnim;
  position: relative;
}

.loader:before, .loader:after {
  content: "";
  border: inherit;
  animation: inherit;
  border-radius: 50%;
  animation-duration: 2s;
  position: absolute;
  inset: -8px;
}

.loader:after {
  animation-duration: 4s;
}

@keyframes loaderAnim {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes expand {
  from {
    color: #0000;
    max-height: 0;
  }

  to {
    color: #4b5563;
    max-height: 500px;
  }
}

.expand {
  max-height: 500px;
  padding-bottom: 2rem;
  animation-name: expand;
  animation-duration: .5s;
}

@keyframes collapse {
  from {
    color: #4b5563;
    max-height: 500px;
  }

  to {
    color: #0000;
    max-height: 0;
  }
}

.collapse {
  max-height: 0;
  animation-name: collapse;
  animation-duration: .3s;
}

@media (width >= 1024px) {
  @keyframes navAnim {
    from {
      color: #000 !important;
    }

    to {
      color: #fff !important;
    }
  }

  .navAnim {
    animation-name: navAnim;
    animation-duration: 3s;
    color: #fff !important;
  }

  @keyframes navOrigin {
    from {
      color: #fff !important;
    }

    to {
      color: #000 !important;
    }
  }

  .navOrigin {
    animation-name: navOrigin;
    animation-duration: 1;
    background-color: #fff;
    color: #000 !important;
  }

  @keyframes headerBgAnim {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .headerBgAnim:before {
    animation-name: headerBgAnim;
    animation-duration: 1s;
  }

  @keyframes headerBgOrigin {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .headerBgOrigin:before {
    animation-name: headerBgOrigin;
    animation-duration: 1s;
  }

  .headerBg {
    position: relative;
  }

  .headerBg:before {
    content: "";
    opacity: 0;
    z-index: -10;
    background: linear-gradient(90deg, #e65c00 0%, #f09819 100%);
    width: 100%;
    height: 100%;
    animation-fill-mode: forwards;
    position: absolute;
    top: 0;
    left: 0;
  }
}
/*# sourceMappingURL=index.41e2ebdf.css.map */
